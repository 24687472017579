var timesRun = 0;
var interval = setInterval(function(){
  $('.blurtext span.past').removeClass('past');
  $('.blurtext span.active').addClass('past').removeClass('active');
  $('.blurtext span.past + span').addClass('active');
  if ($('.blurtext span.active').length == 0){
    $('.blurtext span:nth-child(1)').addClass('active');
  }
  timesRun += 1;
    if(timesRun === 60){
        clearInterval(interval);
    }
}, 300);


$(document).ready(function(){

    setTimeout(function(){
        $('.bring2').show();
        $('.blurtext').css({'padding-top': '15px'});


    },10000);

  $('.flip').click(function(){
    $('.cont-flip').toggleClass('flipped');
    return false;
  });
});

$(document).ready(function(){
  $('.flip2').click(function(){
    $('.cont-flip2').toggleClass('flipped2');
    return false;
  });
});



    (function(){

    })();


    function send(){
      //alert("hola");
        // parameters: service_id, template_id, template_parameters

        var from_name = $('input[name=from_name]').val(),
            company = $('input[name=company]').val(),
            telephone = $('input[name=telephone]').val(),
            from_email = $('input[name=from_email]').val(),
            message = $('textarea[name=message]').val()
            ;

        if(!from_name) return alert('Please specify your name');
        if(!company) return alert('Please specify your company');
        if(!telephone) return alert('Please specify your telephone');
        if(!from_email) return alert('Please specify your email');
        if(!message) return alert('Please specify your message');

        $('.loading').show();
        $('button.send').attr('disabled',true);
        emailjs.init("user_DkcsCqPMFWBBbY5WMYob6");
        emailjs.send("default_service","template_AMnxJSDn",{from_name: from_name, company: company, telephone: telephone, from_email: from_email, message: message})
            .then(function(response) {
                $('.loading').hide();
                $('button.send').prop('disabled',false);

                $('input[name=from_name]').val('');
                $('input[name=company]').val('');
                $('input[name=telephone]').val('');
                $('input[name=from_email]').val('');
                $('textarea[name=message]').val('');


                //alert(response.text);
                alert('Thank you. We have received your message. We will get in touch with you very soon.');
                console.log("SUCCESS. status=%d, text=%s", response.status, response.text);

                var data = {
                    properties: [
                        {
                            property: 'email',
                            value: from_email
                        },
                        {
                            property: 'firstname',
                            value: from_name
                        },
                        {
                            property: 'company',
                            value: company
                        },
                        {
                            property: 'phone',
                            value: telephone
                        }
                    ]
                };
                //creating Contact
                $.ajax({
                    url: 'http://54.88.202.19/email.php',
                    type: 'post',
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                    data: JSON.stringify(data)
                })

                .done(function(res) {
                    console.log('hb-success','contact',res);
                })
                .fail(function(res) {
                    console.log('hb-error','contact',res);
                });

            }, function(err) {
                $('.loading').hide();
                $('button.send').prop('disabled',false);
                console.log("FAILED. error=", err);
                alert(err.service_error);

            });
    }
    